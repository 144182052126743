.app-container{
    min-height: 100vh;
    display: inline-grid;
    /* place-items:center; */
    padding:0;
    text-align:center;
    overflow-X: visible; 
}

canvas{
    height: 100vh;
    width: 100vw;
}



#root {
	display: grid;
	grid-template-columns: 10vw 80vw 10vw;
	background-color:var(--bg-base);
}
body{
	--bg-base: #000000;
	 background: var(--bg-base);
	 margin:0;
 }
 theme-toggle{
	margin: 25px;
	
 }
 body[light-theme], body[light-theme] *, body[light-theme] boss-card{
	 --bg-base: #7da08e;
	 --text-color: #000000;
 }
 boss-card{
	place-self: center;
    pointer-events: none;
 }
 boss-card h1{
	line-height: 55px;
	margin-bottom: 0;
    overflow: hidden;
 }
 boss-card span{
    overflow: hidden;
 }
 boss-card h1,
 boss-card h2,
 boss-card span{
color: var(--text-color);
 }

.app-container{
	grid-area: 1 / 2;
	min-height: 100vh;
	text-align: center;
	display: grid;
	place-content: center;
	padding: 0 20px
}
h1 span {
	display: block;
}

	
  body[dark-theme], body[dark-theme] *, body[dark-theme] boss-card{
	--bg-base: #000000;
	--text-color: #ffffff;
	
}
.skills{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, max-content));
	grid-auto-flow: row;
	place-content: center;
	column-gap: 20px;
	row-gap: 50px;
	/* display: flex; */
	/* justify-content: space-evenly; */
	align-items: baseline;
}
.skills boss-card:nth-child(1){
	box-sizing: border-box;
   /* --bg-base: #000000; */
   --text-color: #ffffff;
   --box-shadow-color: #61dafb;
}
.skills boss-card:nth-child(2){
   /* --bg-base: #000000; */
   --box-shadow-color: #f0db4f;
   --text-color: #ffffff;
}
.skills boss-card:nth-child(3){
   /* --bg-base: #000000; */
   --box-shadow-color: #e34f26;
   --text-color: #ffffff;
}
.skills boss-card:nth-child(4){
   /* --bg-base: #000000; */
   --box-shadow-color: #2965f1;
   --text-color: #ffffff;
}
.skills boss-card:nth-child(5){
   /* --bg-base: #000000; */
   --box-shadow-color: #e34f26;
   --text-color: #ffffff;
}
boss-card[card-style*=article-preview]{
--text-color: #ffffff;
}
button.theme-toggle{
padding: 5px;
display: inline-grid;
grid-template-columns: 1fr 1fr;
/* place-content: center; */
gap:5px;
border-radius:20px;
}
.theme-toggle{
position: relative;
border: none;
padding: 0;
transition: all .3s ease;
}
.theme-toggle span{
margin: 5px;
pointer-events: none;
text-align:center;
align-self:center;
font-size:18px;
font-weight: bolder;
border-radius:20px;
}
.theme-toggle[dark-theme]:after{
content: '';
width: 50%;
background-color:green;
height:100%;
position: absolute;
z-index:0;
opacity: .5;
left: 50%;
top:0;
border-radius:0px 40px 40px 0px;
/* transition: all .4s ease-in; */
}
.theme-toggle[light-theme]:after{
content: '';
width: 50%;
background-color:green;
height:100%;
position: absolute;
z-index:0;
opacity: .5;
left: 0%;
border-radius:40px 0px 0px 40px;
/* transition: all .4s ease-in; */
}
section.skills{
margin-top: 40px;
}
.python-django.glow{
box-shadow: 7px 7px 20px #3b5d50, -8px 8px 20px #3b5d50, 7px -7px 20px #6ea38f, -8px -8px 20px #6ea38f;
border-radius: 20px;
}
.typescript.glow{
box-shadow: 7px 7px 20px #6290c2, -8px 8px 20px #6290c2, 7px -8px 20px #6290c2, -8px -8px 20px #6290c2;
border-radius: 20px;
}
.web-components.glow{
box-shadow: 7px 7px 20px #e44d26, -8px 8px 20px #f16529, 7px -8px 20px #f16529, -8px -8px 20px #f16529;
border-radius: 20px;
}
.typescript.glow svg{
stroke: #2f72bc;
}
body[light-theme] .typescript.glow svg{
fill: var(--bg-base);
}
@media(max-width:900px){
.skills boss-card svg{
width:100px;
height:100px;
}
}
@media(max-width: 500px){
    .app-container{
        padding:70px 0;
        box-sizing: border-box;
    }
    boss-card h1{
        font-size: 24px;
    }
    boss-card h2{
        font-size: 18px;
    }
    theme-toggle{
        --font-size: 15px;
    }
}